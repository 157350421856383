import React from "react"
import ReactDOM from "react-dom"
// const autoBind = require("auto-bind")

import CIDRCheck from "./cidr-check.jsx"

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}

        // autoBind(this)
    }

    componentDidMount() {
    }

    render(){
        return (
            <CIDRCheck/>
        )
    }
}

ReactDOM.render(<App />, document.getElementById("app"))
